<template lang="html">
  <div>
    <app-nav-bar  @scroll-to-section="scrollToSection"></app-nav-bar>
    <div>
      <app-company-background></app-company-background>
      <section class="about" ref="about"><app-about-us></app-about-us></section>
      <section class="service" ref="service"><app-product></app-product></section>
      <section class="tech" ref="tech"><app-technology></app-technology></section>
      <app-contact-us></app-contact-us>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    appNavBar: () => import("@/components/Navbar"),
    appService: () => import("@/components/Service"),
    appProduct: () => import("@/components/Product"),
    appTechnology: () => import("@/components/Technology"),
    appCompanyBackground: () => import("@/components/CompanyBackground"),
    appAboutUs: () => import("@/components/AboutUs"),
    appFooter: () => import("@/components/Footer"),
    appContactUs: () => import("@/components/ContactUs"),
  },

  methods:{
     scrollToSection(section) {
      this.$refs[section].scrollIntoView({ behavior: 'smooth' });
    },
  }
};
</script>

<style lang="css" scoped></style>
